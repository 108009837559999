/*
 *  Main component for the View Customer page
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
 */
import React from "react";
import { Trans, withTranslation as translate } from "react-i18next";
import { composeHoc, AuthReq } from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import CustomerTable from "./CustomerTable";
import "../../../css/view/Main.css";
import get from "lodash/get";
import { Card, Dropdown, Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";
import EditCustomerModal from "./modals/EditCustomerModal";
import BanCustomerModal from "./modals/BanCustomerModal";
import BanFacilityModal from "./modals/BanFacilityModal";
import UnbanCustomerModal from "./modals/UnbanCustomerModal";
import UnbanFacilityModal from "./modals/UnbanFacilityModal";
import EditFacilityModal from "./modals/EditFacilityModal";
import FacilityScheduleModal from "./modals/FacilityScheduleModal";
import RetireFacilityModal from "./modals/RetireFacilityModal";
import UnretireFacilityModal from "./modals/UnretireFacilityModal";
import RetireCustomerModal from "./modals/RetireCustomerModal";
import UnretireCustomerModal from "./modals/UnretireCustomerModal";
import queryString from "query-string";
import RetireDeviceModal from "../devices/RetireDeviceModal";
import UnretireDeviceModal from "../devices/UnretireDeviceModal";
import ResendActivationCodeModal from "../devices/ResendActivationCodeModal";
import BanDeviceModal from "../devices/BanDeviceModal";
import UnbanDeviceModal from "../devices/UnbanDeviceModal";
import DistributorDropdownFilter from "../../view/distributors/DistributorDropdownFilter";
import { withRouter } from "react-router-dom";
import { Duration } from "luxon";
const Auth = new AuthService();

class ViewCustomers extends React.Component {
    state = {
        isLoading: false,
        customerTableArray: [],
        distributorDropdownArray: [
            {
                key: 0,
                value: 0,
                text: this.props.t("All Distributors"),
            },
        ],
        searchParam: "",
        customerFilter: "active",
        distributorFilter: 0,
        queryString: "",
    };

    notifySuccess = (operation, subject, name) =>
        toast(`Successfully ${operation} ${subject} ${name}`, { type: toast.TYPE.DEFAULT, autoClose: 5000 });

    notifyFailure = () =>
        toast(`Operation failed. Please refresh this page and try again.`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    componentDidMount() {
        document.title = "View Customers";

        if (queryString.parse(this.props.location.search).name) {
            const searchParam = queryString.parse(this.props.location.search).name;
            this.setState({
                searchParam,
            });
        }

        this.preparePage();
    }

    generatePreferredFirmwareIcon = (preferred) => {
        if (preferred) {
            return (
                <Popup
                    trigger={<Icon className="green-icon" name="check circle" />}
                    content={
                        <div>
                            <p>This is preferred software.</p>
                        </div>
                    }
                />
            );
        } else if (preferred === false) {
            return (
                <Popup
                    trigger={<Icon className="orange-icon" name="exclamation circle" />}
                    content={
                        <div>
                            <p>This is not preferred software.</p>
                        </div>
                    }
                />
            );
        }
    };

    preparePage = () => {
        this.setState({
            isLoading: true,
        });
        return Auth.fetch(`/api/customer-facilities`, {
            method: "GET",
        })
            .then((data) => {
                // take shallow copy of array in state to begin building distributor dropdown array
                const distributorDropdownArray = [...this.state.distributorDropdownArray];
                let customerCount = 0;
                let facilityCount = 0;
                let deviceCount = 0;
                const customerTableArray = data.map((customer) => {
                    customerCount++;
                    let customerSearchString = customer.customer_name;
                    const customerDeviceCommunicationStatus = {
                        greenDevices: 0,
                        yellowDevices: 0,
                        redDevices: 0,
                    };
                    let customerCycleCount = 0;

                    const distributor = customer["distributor"];
                    customerSearchString = customerSearchString + distributor["distributor_name"];
                    // Create an array of unique distributors
                    if (
                        distributorDropdownArray.some((elem) => {
                            return distributor["id"] === elem["value"];
                        }) === false
                    ) {
                        distributorDropdownArray.push({
                            key: distributor["id"],
                            value: distributor["id"],
                            text: distributor["distributor_name"],
                        });
                    }

                    const customerFacilityTableArray = customer["facilities"].map((facility) => {
                        facilityCount++;
                        const facilityDeviceConfigs = facility["device_configs"].map((device_config) => {
                            deviceCount++;
                            customerCycleCount += device_config["cycle_count"];
                            // customerErrorCount += device_config['error_count'];
                            this.calculateDeviceType(
                                device_config["device"]["time_of_last_communication"],
                                customerDeviceCommunicationStatus
                            );
                            // add serial number to the searchable string
                            customerSearchString =
                                customerSearchString + " " + device_config["device"]["serial_number"];
                            // add firmware version to the searchable string
                            customerSearchString =
                                customerSearchString + " " + get(device_config, "current_firmware", "");
                            return {
                                comm_status: this.calculateTimeLastCommunicated(
                                    device_config["device"]["time_of_last_communication"]
                                ),
                                serial_number: (
                                    <Link
                                        target="_blank"
                                        id="linkToFindAMachine"
                                        to={`/find-a-machine?serial=${device_config.device.serial_number}`}>
                                        {device_config.device.serial_number}
                                    </Link>
                                ),
                                software_version: (
                                    <React.Fragment>
                                        {this.generatePreferredFirmwareIcon(
                                            get(device_config, "current_firmware_preferred", null)
                                        )}
                                        {get(device_config, "current_firmware", null)}
                                    </React.Fragment>
                                ),
                                links: (
                                    <Link target="_blank" to={`/sa/dashboard/${device_config.id}`}>
                                        View Dashboard
                                    </Link>
                                ),
                                deleted_status: device_config.device.deleted,
                                activated_status: device_config.device.activated,
                                banned_status: device_config.device.banned,
                                actions: this.deviceActions(device_config),
                            };
                        });
                        customerSearchString = customerSearchString + " " + facility["facility_name"];
                        customerSearchString = customerSearchString + " " + facility["facility_type_display"];
                        return {
                            facility_name: facility["facility_name"],
                            facility_address: this.facilityAddress(facility),
                            subRows: facilityDeviceConfigs,
                            facility_type: facility["facility_type_display"],
                            facility_biochallenge: this.facilityBiochallengeType(facility),
                            facility_cook_params: this.facilityCookParams(facility),
                            facility_timezone: facility["region_setting"]["timezone"]["timezone"],
                            facility_timezone_display_label: facility["region_setting"]["timezone"]["display_label"],
                            facility_elevation: `${facility["elevation"]} ft`,
                            deleted_status: facility.deleted,
                            banned_status: facility.banned,
                            actions: this.facilityActions(facility, customer),
                        };
                    });
                    return {
                        customer_name: customer.customer_name,
                        subRows: customerFacilityTableArray,
                        actions: this.customerActions(customer),
                        customer_device_summary: this.customerDeviceSummary(customerDeviceCommunicationStatus),
                        search_string: customerSearchString,
                        banned_status: customer["banned"],
                        deleted_status: customer["deleted"],
                        customer_cycle_count: customerCycleCount,
                        distributor_name: customer["distributor"]["distributor_name"],
                        distributor_id: customer["distributor"]["id"],
                    };
                });

                this.setState({
                    isLoading: false,
                    customerTableArray,
                    customerCount,
                    facilityCount,
                    deviceCount,
                    distributorDropdownArray,
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                this.notifyFailure();
                this.setState({
                    isLoading: false,
                });
            });
    };

    customerDeviceSummary = (customerDeviceCommunicationStatus) => {
        const iconStyleGreen = { color: "rgb(41, 170, 23)", fontSize: "1.33333333em", paddingLeft: "8px" };
        const iconStyleYellow = { color: "#ff8600", fontSize: "1.33333333em", paddingLeft: "8px" };
        const iconStyleRed = { color: "rgb(214, 10, 10)", fontSize: "1.33333333em", paddingLeft: "8px" };
        return (
            <div data-greensum={customerDeviceCommunicationStatus["greenDevices"]}>
                <Icon style={iconStyleGreen} name="check circle" /> {customerDeviceCommunicationStatus["greenDevices"]}
                <Icon style={iconStyleYellow} name="minus circle" />{" "}
                {customerDeviceCommunicationStatus["yellowDevices"]}
                <Icon style={iconStyleRed} name="remove circle" /> {customerDeviceCommunicationStatus["redDevices"]}
            </div>
        );
    };

    calculateDeviceType = (timeLastCommunicated, customerDeviceCommunicationStatus) => {
        if (timeLastCommunicated) {
            //Get rid of milliseconds
            const timeDelta = Date.now() / 1000 - Date.parse(timeLastCommunicated) / 1000;

            if (timeDelta < 300) {
                customerDeviceCommunicationStatus["greenDevices"] += 1;
            } else if (timeDelta > 300 && timeDelta < 86400) {
                customerDeviceCommunicationStatus["yellowDevices"] += 1;
            } else {
                customerDeviceCommunicationStatus["redDevices"] += 1;
            }
        }
    };

    calculateTimeLastCommunicated = (timeLastCommunicated) => {
        if (timeLastCommunicated) {
            //Get rid of milliseconds
            const timeDelta = Date.now() / 1000 - Date.parse(timeLastCommunicated) / 1000;

            const iconStyle = { color: "rgb(41, 170, 23)", fontSize: "1.33333333em", paddingLeft: "8px" };
            const duration = Duration.fromObject({ seconds: timeDelta }).shiftTo("days", "hours", "minutes", "seconds");
            const formattedDelta = `${duration.days > 0 ? duration.days + " days" : ""} ${
                duration.hours > 0 ? duration.hours + " hours" : ""
            } ${duration.minutes > 0 ? duration.minutes + " minutes" : ""} ${
                duration.seconds > 0 ? Math.round(duration.seconds) + " seconds" : ""
            }.`;
            const iconTitle = `The machine has last communicated with the portal ${formattedDelta} ago.`;

            if (timeDelta < 300) {
                iconStyle.color = "rgb(41, 170, 23)";
                return (
                    <details data-value={timeLastCommunicated}>
                        <summary>
                            <Icon title={iconTitle} style={iconStyle} name="check circle" />
                        </summary>
                        <p>
                            <strong>{formattedDelta}</strong>
                            <br />
                            since last
                            <br />
                            communication with
                            <br />
                            the portal.
                        </p>
                    </details>
                );
            } else if (timeDelta > 300 && timeDelta < 86400) {
                iconStyle.color = "#ff8600";
                return (
                    <details data-value={timeLastCommunicated}>
                        <summary>
                            <Icon title={iconTitle} style={iconStyle} name="minus circle" />
                        </summary>
                        <p>
                            <strong>{formattedDelta}</strong>
                            <br />
                            since last
                            <br />
                            communication with
                            <br />
                            the portal.
                        </p>
                    </details>
                );
            } else {
                iconStyle.color = "rgb(214, 10, 10)";
                return (
                    <details data-value={timeLastCommunicated}>
                        <summary>
                            <Icon title={iconTitle} style={iconStyle} name="remove circle" />
                        </summary>
                        <p>
                            <strong className="red">{formattedDelta}</strong>
                            <br />
                            since last
                            <br />
                            communication with
                            <br />
                            the portal.
                        </p>
                    </details>
                );
            }
        }
    };

    facilityAddress = (facility) => {
        const { thoroughfare, sub_thoroughfare, locality, sub_locality, postal_code, region_setting } = facility;

        const safeRegionName = get(region_setting, "region_name", "");
        const safeCountry = get(region_setting, "country", "");
        return (
            <div>
                {thoroughfare} {sub_thoroughfare && `${sub_thoroughfare}`}
                <br />
                {sub_locality} {locality}, {safeRegionName} {safeCountry.toUpperCase()} {postal_code}
            </div>
        );
    };

    facilityBiochallengeType = (facility) => {
        let biochallengeInfo = "";
        if (facility["facility_type"] === "airport") {
            biochallengeInfo = <div>Airport</div>;
        } else {
            const biochallengeRaw = facility["region_setting"]["bio_interval_type"];
            const biochallengeType = biochallengeRaw.charAt(0).toUpperCase() + biochallengeRaw.slice(1);
            const incubationPeriod = this.facilityIncubationPeriod(facility["incubation_period"]);
            biochallengeInfo = (
                <div>
                    {biochallengeType}
                    <br />
                    {incubationPeriod}
                </div>
            );

            // if it's a bio validation facility
            if (facility["bio_validation_waste_type"] !== "indicator") {
                const bioValidationType = <strong>{facility["bio_validation_waste_type_display"]}</strong>;
                return (
                    <div>
                        {bioValidationType}
                        <br />
                        {biochallengeType}
                        <br />
                        {incubationPeriod}
                    </div>
                );
            }
        }
        return biochallengeInfo;
    };

    facilityCookParams = (facility) => {
        const cookTime = facility["region_setting"]["min_cook_time"];
        const facilityOverride = facility["enable_facility_specific_cooktime"];
        const isAirport = facility["facility_type"] === "airport";

        if (facilityOverride) {
            const facilityCookTime = facility["facility_specific_cooktime"];
            return (
                <React.Fragment>
                    <strong className="red">Facility cook time override enabled</strong>
                    <div>
                        Min Time: {facilityCookTime} {facilityCookTime > 1 ? "mins" : "min"}
                    </div>
                    <div>Min Temp: {facility["region_setting"]["min_cook_temp"]} °C</div>
                </React.Fragment>
            );
        } else if (isAirport) {
            return (
                <React.Fragment>
                    <strong>Airport cook time enabled</strong>
                    <div>Min Time: 55 mins</div>
                    <div>Min Temp: 134 °C</div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div>
                        Min Time: {cookTime} {cookTime > 1 ? "mins" : "min"}
                    </div>
                    <div>Min Temp: {facility["region_setting"]["min_cook_temp"]} °C</div>
                </React.Fragment>
            );
        }
    };

    facilityIncubationPeriod = (incubationPeriod) => {
        if (incubationPeriod === 172800) {
            return "48 Hour incubation period";
        } else if (incubationPeriod === 86400) {
            return "24 Hour incubation period";
        } else {
            return incubationPeriod;
        }
    };

    facilityActions = (facility, customer) => {
        const { t } = this.props;
        return (
            <div data-facility={facility} className="overflow-visible-td">
                <Dropdown id={facility["id"]} pointing="top right" icon="ellipsis horizontal">
                    <Dropdown.Menu>
                        <Dropdown.Header content={<h4>{t("Facility Actions")}</h4>} />
                        <Dropdown.Divider />
                        {customer["deleted"] ? (
                            <Dropdown.Item
                                text={
                                    <div>
                                        <h5>{customer["customer_name"]} has been archived.</h5>
                                        <h5>Facility actions are disabled until the customer is reactivated.</h5>
                                    </div>
                                }
                            />
                        ) : (
                            <React.Fragment>
                                <AuthReq
                                    userGroup={this.props.group}
                                    requiredRoles={[
                                        "SterilisSuperUsers",
                                        "SterilisPortalUsers",
                                        "DistributorAdmins",
                                        "SterilisWasteTypeAdmin",
                                        "DistributorFSEs",
                                    ]}>
                                    {facility.deleted === null && (
                                        <Dropdown.Item
                                            onClick={() => this.triggerEditFacilityModal(facility)}
                                            text={<h5>{t("Edit Facility")}</h5>}
                                        />
                                    )}
                                </AuthReq>
                                <AuthReq
                                    userGroup={this.props.group}
                                    requiredRoles={[
                                        "SterilisSuperUsers",
                                        "SterilisPortalUsers",
                                        "DistributorAdmins",
                                        "SterilisWasteTypeAdmin",
                                    ]}>
                                    {facility.deleted === null && (
                                        <Dropdown.Item
                                            onClick={() => this.triggerFacilityScheduleModal(facility)}
                                            text={<h5>{t("Edit Facility Schedule")}</h5>}
                                        />
                                    )}
                                </AuthReq>

                                <AuthReq
                                    userGroup={this.props.group}
                                    requiredRoles={[
                                        "SterilisSuperUsers",
                                        "SterilisPortalUsers",
                                        "DistributorAdmins",
                                        "SterilisWasteTypeAdmin",
                                    ]}>
                                    {this.retireFacilityItem(facility, customer)}
                                    {facility.deleted === null && this.banFacilityItem(facility, customer)}
                                </AuthReq>
                            </React.Fragment>
                        )}
                        <AuthReq
                            userGroup={this.props.group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "DistributorAdmins",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            {facility.banned === false && facility.deleted === null && (
                                <Dropdown.Item
                                    id={"assign-device-to-facility-" + facility["id"]}
                                    onClick={() =>
                                        this.props.history.push(`/assign/device-to-facility?facility=${facility["id"]}`)
                                    }
                                    text={
                                        <h5>
                                            <Trans ns="translations" i18nKey="dropdown_assign_devices_to_facility">
                                                Assign devices <br /> to this facility
                                            </Trans>
                                        </h5>
                                    }
                                />
                            )}
                        </AuthReq>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    banFacilityItem = (facility, customer) => {
        const { t } = this.props;
        if (facility.banned) {
            return (
                <Dropdown.Item
                    id={"unsuspend-" + facility["id"]}
                    onClick={() => this.triggerUnbanFacilityModal(facility)}
                    text={<h5>{t("Reactivate Facility")}</h5>}
                />
            );
        } else {
            return (
                <Dropdown.Item
                    id={"suspend-" + facility["id"]}
                    onClick={() => this.triggerBanFacilityModal(facility, customer)}
                    text={<h5>{t("Suspend Facility")}</h5>}
                />
            );
        }
    };

    triggerBanFacilityModal = (facility, customer) => {
        this.setState((prevState) => {
            return {
                banFacilityModal: !prevState.banFacilityModal,
                facility,
                customer,
            };
        });
    };

    triggerUnbanFacilityModal = (facility) => {
        this.setState((prevState) => {
            return {
                unbanFacilityModal: !prevState.unbanFacilityModal,
                facility,
                customer: null,
            };
        });
    };

    retireFacilityItem = (facility, customer) => {
        const { t } = this.props;
        if (facility.deleted) {
            return (
                <Dropdown.Item
                    id={"unretire-" + facility["id"]}
                    onClick={() => this.triggerUnretireFacilityModal(facility)}
                    text={<h5>{t("Reactivate Facility")}</h5>}
                />
            );
        } else {
            return (
                <Dropdown.Item
                    id={"retire-" + facility["id"]}
                    onClick={() => this.triggerRetireFacilityModal(facility, customer)}
                    text={<h5>{t("Archive Facility")}</h5>}
                />
            );
        }
    };

    triggerUnretireFacilityModal = (facility) => {
        this.setState((prevState) => {
            return {
                unretireFacilityModal: !prevState.unretireFacilityModal,
                facility,
                customer: null,
            };
        });
    };

    triggerRetireFacilityModal = (facility, customer) => {
        this.setState((prevState) => {
            return {
                retireFacilityModal: !prevState.retireFacilityModal,
                facility,
                customer,
            };
        });
    };

    triggerEditFacilityModal = (facility) => {
        this.setState((prevState) => {
            return {
                editFacilityModal: !prevState.editFacilityModal,
                facility,
            };
        });
    };

    triggerFacilityScheduleModal = (facility) => {
        this.setState((prevState) => {
            return {
                facilityScheduleModal: !prevState.facilityScheduleModal,
                facility,
            };
        });
    };
    customerActions = (customer) => {
        const { t, group } = this.props;
        return (
            <div data-customer={customer} className="overflow-visible-td">
                <Dropdown id={customer["id"]} pointing="top right" icon="ellipsis horizontal">
                    <Dropdown.Menu>
                        <Dropdown.Header content={<h4>{t("Customer Actions")}</h4>} />
                        <Dropdown.Divider />
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "DistributorAdmins",
                                "SterilisWasteTypeAdmin",
                                "DistributorFSEs",
                            ]}>
                            {customer.deleted === null && (
                                <Dropdown.Item
                                    onClick={() => this.triggerEditCustomerModal(customer)}
                                    text={<h5>{t("Edit Customer")}</h5>}
                                />
                            )}
                        </AuthReq>
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "DistributorAdmins",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            {this.retireCustomerItem(customer)}
                            {customer.deleted === null && this.banCustomerItem(customer)}
                        </AuthReq>
                        <AuthReq userGroup={group} requiredRoles={["SterilisSuperUsers"]}>
                            <Dropdown.Item
                                onClick={() => this.syncCustomeOperators(customer)}
                                text={<h5>{t("Sync Operators")}</h5>}
                            />
                        </AuthReq>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    retireCustomerItem = (customer) => {
        const { t } = this.props;
        if (customer.deleted) {
            return (
                <Dropdown.Item
                    id={"unretire-" + customer["id"]}
                    onClick={() => this.triggerUnretireCustomerModal(customer)}
                    text={<h5>{t("Reactivate Customer")}</h5>}
                />
            );
        } else {
            return (
                <Dropdown.Item
                    id={"retire-" + customer["id"]}
                    onClick={() => this.triggerRetireCustomerModal(customer)}
                    text={<h5>{t("Archive Customer")}</h5>}
                />
            );
        }
    };

    banCustomerItem = (customer) => {
        const { t } = this.props;
        if (customer.banned) {
            return (
                <Dropdown.Item
                    id={"unretire-" + customer["id"]}
                    onClick={() => this.triggerUnbanCustomerModal(customer)}
                    text={<h5>{t("Reactivate Customer")}</h5>}
                />
            );
        } else {
            return (
                <Dropdown.Item
                    id={"retire-" + customer["id"]}
                    onClick={() => this.triggerBanCustomerModal(customer)}
                    text={<h5>{t("Suspend Customer")}</h5>}
                />
            );
        }
    };

    syncCustomeOperators = (customer) => {
        this.setState({ isLoading: true });
        Auth.fetch(`/api/sync-customer-operators/`, {
            method: "POST",
            body: JSON.stringify({ customer_id: customer.id }),
        })
            .then((data) => {
                this.setState({ isLoading: false });
                this.notifySuccess("synced", "operators for", customer.customer_name);
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                this.notifyFailure();
            });
    };

    triggerEditCustomerModal = (customer) => {
        this.setState((prevState) => {
            return {
                editCustomerModal: !prevState.editCustomerModal,
                customer: customer,
            };
        });
    };
    triggerUnretireCustomerModal = (customer) => {
        this.setState((prevState) => {
            return {
                unretireCustomerModal: !prevState.unretireCustomerModal,
                customer: customer,
            };
        });
    };
    triggerRetireCustomerModal = (customer) => {
        this.setState((prevState) => {
            return {
                retireCustomerModal: !prevState.retireCustomerModal,
                customer: customer,
            };
        });
    };
    triggerUnbanCustomerModal = (customer) => {
        this.setState((prevState) => {
            return {
                unbanCustomerModal: !prevState.unbanCustomerModal,
                customer: customer,
            };
        });
    };
    triggerBanCustomerModal = (customer) => {
        this.setState((prevState) => {
            return {
                banCustomerModal: !prevState.banCustomerModal,
                customer: customer,
            };
        });
    };

    deviceActions = (device_config) => {
        const { t, group } = this.props;
        return (
            <div data-device={device_config} className="overflow-visible-td">
                <Dropdown id={device_config["id"]} pointing="top right" icon="ellipsis horizontal">
                    <Dropdown.Menu>
                        <Dropdown.Header content={<h4>{t("Device Actions")}</h4>} />
                        <Dropdown.Divider />
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "FactoryWorkers",
                                "DistributorAdmins",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            <Dropdown.Item
                                onClick={() =>
                                    this.props.history.push(
                                        `/assign/device-to-facility?device_config=${device_config["id"]}`
                                    )
                                }
                                text={
                                    <h5>
                                        {t("Assign device")}
                                        <br />
                                        {t("to a different facility")}
                                    </h5>
                                }
                            />
                        </AuthReq>
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "FSEs",
                                "ExternalFSEs",
                                "FactoryWorkers",
                                "DistributorAdmins",
                                "DistributorFSEs",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            <Dropdown.Item
                                onClick={() =>
                                    this.props.history.push(
                                        `/assign/software-to-devices?device_config=${device_config["id"]}`
                                    )
                                }
                                text={<h5>{t("Assign software to device")}</h5>}
                            />
                        </AuthReq>
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "DistributorAdmins",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            {this.retireDeviceItem(device_config)}
                        </AuthReq>
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "FSEs",
                                "ExternalFSEs",
                                "FactoryWorkers",
                                "DistributorAdmins",
                                "DistributorFSEs",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            {device_config.device.deleted === null && this.resendActivationCodeItem(device_config)}
                        </AuthReq>
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "FactoryWorkers",
                                "DistributorAdmins",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            {device_config.device.deleted === null && this.banDeviceItem(device_config)}
                        </AuthReq>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    banDeviceItem = (device_config) => {
        const { t } = this.props;
        if (device_config.device.banned) {
            return (
                <Dropdown.Item
                    id={"unbandevice-" + device_config["id"]}
                    onClick={() => this.triggerUnbanDeviceModal(device_config)}
                    text={<h5>{t("Reactivate Device")}</h5>}
                />
            );
        } else {
            return (
                <Dropdown.Item
                    id={"bandevice-" + device_config["id"]}
                    onClick={() => this.triggerBanDeviceModal(device_config)}
                    text={<h5>{t("Suspend Device")}</h5>}
                />
            );
        }
    };

    triggerBanDeviceModal = (deviceConfig) => {
        this.setState((prevState) => {
            return {
                showBanDeviceModal: !prevState.showBanDeviceModal,
                modalSerial: get(deviceConfig, "device.serial_number", null),
                modalDeviceID: get(deviceConfig, "device.id", null),
            };
        });
    };

    triggerUnbanDeviceModal = (deviceConfig) => {
        this.setState((prevState) => {
            return {
                showUnbanDeviceModal: !prevState.showUnbanDeviceModal,
                modalSerial: get(deviceConfig, "device.serial_number", null),
                modalDeviceID: get(deviceConfig, "device.id", null),
            };
        });
    };

    retireDeviceItem = (device_config) => {
        const { t } = this.props;
        if (device_config.device.deleted) {
            return (
                <Dropdown.Item
                    id={"unretire-" + device_config["id"]}
                    onClick={() => this.triggerUnretireDeviceModal(device_config)}
                    text={<h5>{t("Reactivate Device")}</h5>}
                />
            );
        } else {
            return (
                <Dropdown.Item
                    id={"retire-" + device_config["id"]}
                    onClick={() => this.triggerRetireDeviceModal(device_config)}
                    text={<h5>{t("Archive Device")}</h5>}
                />
            );
        }
    };

    triggerRetireDeviceModal = (deviceConfig) => {
        this.setState((prevState) => {
            return {
                retireDeviceModal: !prevState.retireDeviceModal,
                modalSerial: get(deviceConfig, "device.serial_number", null),
                modalDeviceID: get(deviceConfig, "device.id", null),
                modalDeviceConfigID: get(deviceConfig, "id", null),
            };
        });
    };

    triggerUnretireDeviceModal = (deviceConfig) => {
        this.setState((prevState) => {
            return {
                unretireDeviceModal: !prevState.unretireDeviceModal,
                modalSerial: get(deviceConfig, "device.serial_number", null),
                modalDeviceID: get(deviceConfig, "device.id", null),
            };
        });
    };

    resendActivationCodeItem = (device_config) => {
        const { t } = this.props;
        const timeDiffSecs = (Date.now() - new Date(device_config.device.reset_password_request_time)) / 1000;
        if (timeDiffSecs < 3000 && device_config.device.reset_password_key) {
            return (
                <Dropdown.Item
                    id={"resetPasswordKey-" + device_config["id"]}
                    onClick={() => this.triggerResendActivationCodeModal(device_config)}
                    text={
                        <div>
                            <h5 className="green">
                                {t("Active initialization key")}: {device_config.device.reset_password_key}
                            </h5>
                            <h5>{t("Reset initialization key")}</h5>
                        </div>
                    }
                />
            );
        } else {
            return (
                <Dropdown.Item
                    id={"resetPasswordKey-" + device_config["id"]}
                    onClick={() => this.triggerResendActivationCodeModal(device_config)}
                    text={<h5>{t("Reset initialization key")}</h5>}
                />
            );
        }
    };

    triggerResendActivationCodeModal = (deviceConfig) => {
        this.setState((prevState) => {
            return {
                resendActivationCodeModal: !prevState.resendActivationCodeModal,
                modalSerial: get(deviceConfig, "device.serial_number", null),
                modalDeviceID: get(deviceConfig, "device.id", null),
                modalCustomerName: get(deviceConfig, "customer", null),
                modalFacilityName: get(deviceConfig, "facility", null),
            };
        });
    };

    handleCustomerFilterChange = (event, data) => {
        this.setState({
            customerFilter: data.value,
        });
    };

    handleDistributorFilterChange = (event, data) => {
        this.setState({
            distributorFilter: data.value,
        });
    };

    render() {
        const {
            isLoading,
            customerTableArray,
            queryString,
            editCustomerModal,
            facilityScheduleModal,
            customer,
            banCustomerModal,
            unbanCustomerModal,
            editFacilityModal,
            facility,
            retireFacilityModal,
            unretireFacilityModal,
            retireCustomerModal,
            unretireCustomerModal,
            customerCount,
            facilityCount,
            deviceCount,
            searchParam,
            customerFilter,
            distributorFilter,
            banFacilityModal,
            unbanFacilityModal,
            retireDeviceModal,
            unretireDeviceModal,
            modalDeviceID,
            modalSerial,
            modalCustomerName,
            modalFacilityName,
            resendActivationCodeModal,
            showBanDeviceModal,
            showUnbanDeviceModal,
            distributorDropdownArray,
        } = this.state;
        const { t, group } = this.props;

        const customerFilterDropdownOptions = [
            {
                key: "all",
                value: "all",
                text: t("All Customers"),
            },
            {
                key: "active",
                value: "active",
                text: t("Active Customers"),
            },
            {
                key: "banned",
                value: "banned",
                text: t("Suspended Customers"),
            },
            {
                key: "deleted",
                value: "deleted",
                text: t("Archived Customers"),
            },
        ];

        const customerFilterDropdown = (
            <div className="view-customer-filter">
                <label>
                    <h4 className="orange-text device-filter-header">{t("Customer Filter")}</h4>
                </label>
                <Dropdown
                    className="device-filter-dropdown"
                    search
                    selection
                    onChange={this.handleCustomerFilterChange}
                    fluid
                    value={customerFilter}
                    options={customerFilterDropdownOptions}
                />
            </div>
        );

        const distributorFilterDropdown = (
            <DistributorDropdownFilter
                group={group}
                onChange={this.handleDistributorFilterChange}
                selectedDistributor={distributorFilter}
                distributorDropdownArray={distributorDropdownArray}
            />
        );

        return (
            <Card fluid>
                <Card.Header style={{ margin: "10px" }}>
                    <h2>{t("View Customers and Facilities")}</h2>
                </Card.Header>
                {/*<ToastContainer />*/}
                <Card.Content className="card-body">
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                            "DistributorFSEs",
                        ]}>
                        {editCustomerModal && (
                            <EditCustomerModal
                                editCustomerModal={editCustomerModal}
                                customer={customer}
                                triggerEditCustomerModal={this.triggerEditCustomerModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                    </AuthReq>

                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {banCustomerModal && (
                            <BanCustomerModal
                                banCustomerModal={banCustomerModal}
                                customer={customer}
                                triggerBanCustomerModal={this.triggerBanCustomerModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                        {unbanCustomerModal && (
                            <UnbanCustomerModal
                                unbanCustomerModal={unbanCustomerModal}
                                customer={customer}
                                triggerUnbanCustomerModal={this.triggerUnbanCustomerModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {retireCustomerModal && (
                            <RetireCustomerModal
                                retireCustomerModal={retireCustomerModal}
                                customer={customer}
                                triggerRetireCustomerModal={this.triggerRetireCustomerModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                        {unretireCustomerModal && (
                            <UnretireCustomerModal
                                unretireCustomerModal={unretireCustomerModal}
                                customer={customer}
                                triggerUnretireCustomerModal={this.triggerUnretireCustomerModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                    </AuthReq>
                    <AuthReq
                        userGroup={this.props.group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                            "DistributorFSEs",
                        ]}>
                        {editFacilityModal && (
                            <EditFacilityModal
                                editFacilityModal={editFacilityModal}
                                facility={facility}
                                triggerEditFacilityModal={this.triggerEditFacilityModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                    </AuthReq>

                    <AuthReq
                        userGroup={this.props.group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                            "DistributorFSEs",
                        ]}>
                        {facilityScheduleModal && (
                            <FacilityScheduleModal
                                facility={facility}
                                facilityScheduleModal={facilityScheduleModal}
                                triggerFacilityScheduleModal={this.triggerFacilityScheduleModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                    </AuthReq>

                    <AuthReq
                        userGroup={this.props.group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {retireFacilityModal && (
                            <RetireFacilityModal
                                retireFacilityModal={retireFacilityModal}
                                facility={facility}
                                triggerRetireFacilityModal={this.triggerRetireFacilityModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                                customer={customer}
                            />
                        )}
                        {unretireFacilityModal && (
                            <UnretireFacilityModal
                                unretireFacilityModal={unretireFacilityModal}
                                facility={facility}
                                triggerUnretireFacilityModal={this.triggerUnretireFacilityModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {banFacilityModal && (
                            <BanFacilityModal
                                banFacilityModal={banFacilityModal}
                                facility={facility}
                                triggerBanFacilityModal={this.triggerBanFacilityModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                                customer={customer}
                            />
                        )}
                        {unbanFacilityModal && (
                            <UnbanFacilityModal
                                unbanFacilityModal={unbanFacilityModal}
                                facility={facility}
                                triggerUnbanFacilityModal={this.triggerUnbanFacilityModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifyFailure={this.notifyFailure}
                                notifySuccess={this.notifySuccess}
                            />
                        )}
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {retireDeviceModal && (
                            <RetireDeviceModal
                                modalDeviceID={modalDeviceID}
                                modalSerial={modalSerial}
                                retireDeviceModal={retireDeviceModal}
                                triggerRetireDeviceModal={this.triggerRetireDeviceModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifySuccess={this.notifySuccess}
                                notifyFailure={this.notifyFailure}
                                modalDeviceConfigID={this.state.modalDeviceConfigID}
                            />
                        )}
                        {unretireDeviceModal && (
                            <UnretireDeviceModal
                                modalDeviceID={modalDeviceID}
                                modalSerial={modalSerial}
                                unretireDeviceModal={unretireDeviceModal}
                                triggerUnretireDeviceModal={this.triggerUnretireDeviceModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifySuccess={this.notifySuccess}
                                notifyFailure={this.notifyFailure}
                            />
                        )}
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FSEs",
                            "ExternalFSEs",
                            "FactoryWorkers",
                            "DistributorAdmins",
                            "DistributorFSEs",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {resendActivationCodeModal && (
                            <ResendActivationCodeModal
                                modalDeviceID={modalDeviceID}
                                modalSerial={modalSerial}
                                modalCustomerName={modalCustomerName}
                                modalFacilityName={modalFacilityName}
                                resendActivationCodeModal={resendActivationCodeModal}
                                triggerResendActivationCodeModal={this.triggerResendActivationCodeModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                fromTable={true}
                                notifyFailure={this.notifyFailure}
                            />
                        )}
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {showBanDeviceModal && (
                            <BanDeviceModal
                                modalDeviceID={modalDeviceID}
                                modalSerial={modalSerial}
                                showBanDeviceModal={showBanDeviceModal}
                                triggerBanDeviceModal={this.triggerBanDeviceModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifySuccess={this.notifySuccess}
                                notifyFailure={this.notifyFailure}
                            />
                        )}
                        {showUnbanDeviceModal && (
                            <UnbanDeviceModal
                                modalDeviceID={modalDeviceID}
                                modalSerial={modalSerial}
                                showUnbanDeviceModal={showUnbanDeviceModal}
                                triggerUnbanDeviceModal={this.triggerUnbanDeviceModal}
                                preparePage={this.preparePage}
                                prepareParameter={queryString}
                                notifySuccess={this.notifySuccess}
                                notifyFailure={this.notifyFailure}
                            />
                        )}
                    </AuthReq>

                    <CustomerTable
                        preparePage={this.preparePage}
                        customerTableArray={customerTableArray}
                        isLoading={isLoading}
                        customerCount={customerCount}
                        facilityCount={facilityCount}
                        deviceCount={deviceCount}
                        searchParam={searchParam}
                        customerFilter={customerFilter}
                        distributorFilter={distributorFilter}
                        customerFilterDropdown={customerFilterDropdown}
                        distributorFilterDropdown={distributorFilterDropdown}
                        group={group}
                    />
                </Card.Content>
            </Card>
        );
    }
}

export default composeHoc(
    withRouter,
    translate("translations"),
    withAuth(
        [
            "SterilisSuperUsers",
            "SterilisPortalUsers",
            "FSEs",
            "ExternalFSEs",
            "FactoryWorkers",
            "DistributorAdmins",
            "DistributorReadOnly",
            "DistributorFSEs",
            "SterilisWasteTypeAdmin",
        ],
        "internalPage"
    )
)(ViewCustomers);
